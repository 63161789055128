.App {
    padding: 50px 0;
}

.home_btn {
    background-color: #2C2E35;
    padding: 10px 0;
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
}
.home_btn:hover
{
    background-color: #c09b58;
    color: #fff;

}
.calc_buttons li:last-child {
    margin-bottom: 0;
}

.form_back {
    background-color: #c09b58;
    padding: 10px 15px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    font-weight: bold;

}

.radio_btns label, .checkboxes label {
    padding: 0 5px;
    margin-right: 10px;
}

.formLabel {
    text-align: left;
    width: 100%;
}

.line_break {
    margin: 40px 0 0 0;
    padding: 0 0 40px 0;
    border-top: 1px solid #e6e6e6;
    display: block;
}

.table {
    text-align: left;
}

.quote_big {
    font-size: 60px;
    line-height: 60px;
    font-weight: bold;
}

.quote_subtext {
    font-size: 12px;
    line-height: 12px;
}

.calc_form {
    text-align: left;
}

.quote_table {
    margin-top: 20px;
}

.container-1{
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    max-width: 900px;
    margin: auto !important;
}

.input-group-text{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.cursor-pointer{
    cursor: pointer;
}

.row{
    margin: 0px !important;
    align-items: center;
}

.bg-1{
    background-color: #c09b58;
    color: #fff;
    text-transform: uppercase;
}
.bg-1 tr th{
    padding: 20px 9px !important;
}
.table-success{
    font-weight: bold;
}
.page-title{
    width: 100%;
    padding: 10px;
    background: #c09b58;
    color: #fff;
    margin-bottom: 10px !important;
}
.page-title h2{
    margin-left: 5px !important;
    text-transform: uppercase;
}
.form-check-input:checked{
    background-color: #c09b58;
    border-color: #c09b58;
}


.input-group-text, .btn-outline-secondary {
    background-color: #c09b58 !important;
    color: #fff !important;
}

.form-control{
    appearance: auto;
}
.form-control:focus{
    box-shadow: none !important;
    border-color: #2C2E35 !important;
    border: 1px solid #000 !important;
}

.btn
{
    color: #fff !important; 
}

.details{
    width: 95%;
    margin: 0px 2.5% 50px 2.5% !important;
    border: 1px solid #f1f1f1;
}
.details-heading{
    border: 1px solid #f1f1f1;
    padding: 10px !important;
}
.details-body{
    border: 1px solid #f1f1f1;
    display: flex !important;
    width: 100%;
}
.details-body-content{
    border-right: 1px solid #f1f1f1;
    width: 50%;
    text-align: center;
    padding: 10px;
}

@media screen and (max-width:800px) {
    .details-body{
        flex-direction: column;
    }
    .details-body-content{
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
        text-align: center;
    }
}

table.margin-top {
    margin-top: 50px;
}
.address-lookup-label {
	display: none;
}
button.next {
  float: right;
  width: auto;
}
.privacy_toggle {
    text-decoration: underline;
    color: #C09B58;
    cursor: pointer;
}
a {
    color: #C09B58;
}
a:hover {
    color: #000;
}
.address-lookup-wrapper {
	width: 100%;
}
.address-lookup-input {
	width: 80%;
	color: #212529;
	border-radius: 5px;
	padding: 0.375rem 0.75rem;
	border: 1px solid #ced4da;
}
.address-lookup-search-button {
	width: 18%;
	margin-left: 2%;
	background: #C09B58;
	border: none;
	color: #fff;
	padding: 0.375rem 0.75rem;
	border-radius: 5px;
	border: 1px solid #C09B58;
}
.address-lookup-select {
	width: 100%;
	color: #212529;
	border-radius: 5px;
	padding: 0.375rem 0.75rem;
	border: 1px solid #ced4da;
	margin-top: 10px;
}
.quote_actions {
	display: flex;
	margin: 50px 0;
}
.print_icon {
	font-size: 20px;
	background-color: #C09B58;
	color: #fff;
	border-radius: 5px;
	padding: 0 10px !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.top_logo {
    max-width: 20%;
    margin: 0 0 50px 0;
}

.loading {
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.3);
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}
#root, .App {
	min-height: 100vh;
	display: flex;
	align-items: center;
	width: 100%;
	background: #e6e6e6;
	flex-direction: column;
	justify-content: center;
}